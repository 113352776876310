<template lang="pug">
.home
  v-carousel(
    :loop="true"
    :navigationEnabled="true"
    :perPageCustom="[[768, 2]]"
    :paginationPadding="5"
    :perPage="1"
  )
  v-filter
  v-list
</template>

<script>
	import { mapMutations } from 'vuex';

	import VFilter from '@/components/blocks/Filter/Filter';
  import VList from '@/components/blocks/List';
  import VCarousel from '@/components/blocks/Carousel/CarouselFull';

  export default {
    name: 'Home',
    components: {
      VFilter,
      VList,
      VCarousel,
    },
		created() {
      this.$store.dispatch('product/clearAll');
      this.$store.dispatch('order/clearAll');

			this.onTheHomePage(true);

      localStorage.removeItem('order')
    },
    methods: {
      ...mapMutations('global', ['onTheHomePage']),
    }
  }
</script>
