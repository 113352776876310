<template lang="pug">
div
  v-type-select(
    @change.self="setAndUpdateType($event)"
  )
  v-finance-forms-mobile(
    @change="reloadFilter"
  )
  
  #filter.filter(v-if="filters.length > 0")
    v-collapse-box(
      :min-height="0"
      :always-show="false"
      :content-show="filterShow"
      button-position="top"
    )
      .btn-collapse(slot="button" slot-scope="data" @click="setFilterShow(!filterShow)")
        span.text(v-if="!data.show") {{ $t("filter.showFilters") }}
        span.text(v-else) {{ $t("filter.hideFilters") }}
        span.bar
      .container
        .row
          .col-md-12
            h3.filter-headline {{ headline }}
        .row
          .filter-wrapper
            pre-loader.filter-container(:size="40" :show="!filtersLoaded")
              .filter-item(
                  v-for="(filter, index) in filters"
                  :key="index"
                  :class="{'item-hidden': !showMore && index > 3}"
                )
                v-drop-down(
                  option-label="value"
                  option-value="key"
                  v-model="selectedFilters[filter.key]"
                  :label="filter.label"
                  :options=" resellerInfo.url.includes('renault') && filter.key === 'models' ? sortedModels(filter.items) : filter.items"
                  :disabled="filter.items.length === 0"
                  :multiple="true"
                  @click="setAndUpdateFilters({name: filter.key, values: $event})"
                )
            .filter-btns-container(
              :class="showMore?'active':''"
            )
              .filter-item
                button.btn.btn-reset.btn-hover(
                  @click="resetFilters" 
                  ) {{ $t("filter.clearFilters") }}
              .filter-item
                button.btn.btn-search.btn-hover(
                  @click="updateVehicles" 
                  ) {{ $t("filter.searchCars") }}
                  
        .row(v-if="filters.length > 4")
          .show-more-row
            .show-more(
              :class="showMore?'active':''"
              @click="setToggleMore(!showMore)"
            ) 
              span.show-more-sign
                span(v-if="!showMore")
                  AddSvg(
                    customClass="expand-filter-btn"
                  )
                span(v-else)
                  RemoveSvg(
                    customClass="expand-filter-btn"
                  )
              span {{ showMore ? $t("filter.fewerOptions") : $t("filter.moreOptions") }}

  .finance-forms-wrapper
    v-finance-forms(
      @change="reloadFilter"
    )

  .filter-sort-block.bordered.m-b-30
    .container.display-flex.contend-flex-and
      v-sort

</template>

<script>
/* eslint-disable */
import { mapState, mapMutations, mapActions, mapGetters, } from "vuex";

import {
  googleAccountRenault,
  googleAccountDacia,
  googleAccountDanmark,
  googleAccountDanmarkDacia } from '@/config/defaultValues'
import { isRenault, isDacia, isDanmarkR, isDanmarkD } from '@/helpers/helpers'


export default {
  data: () => ({
    presettedParams: {},
  }),
  components: {
    VDropDown: () => import('@/components/helps/DropDown'),
    VCollapseBox: () => import('@/components/helps/CollapseBox'),
    VSort: () => import('@/components/blocks/Filter/Sort'),
    VFinanceFormsMobile: () => import('./../FinanceForms/FinanceFormsMobile'),
    VFinanceForms: () => import('./../FinanceForms/FinanceForms'),
    VTypeSelect: () => import('./TypeSelect'),
    AddSvg: () => import('@/components/svg/add'),
    RemoveSvg: () => import('@/components/svg/remove'),
  },
  created(){
    if (this.partnerKey && this.type.length) {
      this.getFilterParams();
    }
  },
  computed: {
    ...mapState('filter', [
      'showMore',
      'dataWasLoaded',
      'filterShow',
      'filters',
      'allTypes',
      'type',
      'filtersLoaded',
      'finance_form_id',
    ]),
    ...mapState('list', [
      'vehicles',
    ]),
    ...mapState('reseller', {
      headline: state => state.customStyle.filterBlock?.headline || '',
      partnerKey: state => state.partnerKey,
      resellerInfo: state => state.resellerInfo,
    }),
    ...mapGetters('filter', [
      'getRouteParams',
      'sortedModels',
    ]),
    type: {
      get() {
        return this.$store.state.filter.type;
      },
      set(value) {
        this.$store.commit('filter/setType', value);
      }
    },
    selectedFilters: {
      get() {
        return this.$store.state.filter.selectedFilters;
      },
      set(value) {
        this.$store.commit('filter/setSelectedFilters', value);
      }
    },
  },
  methods: {
    ...mapActions('filter', [
      'getAllFilterData',
    ]),
    ...mapMutations('filter', [
      'setToggleMore',
      'setDataLoaded',
      'setFilterShow',
      'setSelectedFilters',
      'setType',
      'setResellerTypeId',
      'removeSelectedFilters',
      'setFiltersLoaded',
      'setAllFinanceForms',
      'setDealerCode',
    ]),
    ...mapActions('list', [
      'getVehicles',
    ]),
    ...mapMutations('list', [
      'setVehiclesLoaded',
    ]),
    ...mapActions('reseller', [
      'updateBuilder',
    ]),
    updateVehicles(flag) { 
      if (this.getRouteParams) {
        this.$nextTick(() => {
          this.$router.replace({
            name: 'home',
            params: { collectionType: this.$route.params.collectionType },
            query: this.getRouteParams
          }).catch(err => err);
        });
      }
      
      this.setVehiclesLoaded(false);

      this.getVehicles(false);

      if (flag !== 'reset') {
        const offsetTopList = document.querySelector('.list').offsetTop;

        window.scrollTo({
          top: offsetTopList - 60,
          behavior: 'smooth'
        });
      }

      if (!isDanmarkR() && !isDanmarkD() && !isRenault() && !isDacia()) return;

        let acc = isDanmarkR() ? googleAccountDanmark : 
          isDanmarkD() ? googleAccountDanmarkDacia : 
          isRenault() ? googleAccountRenault : 
          isDacia() ? googleAccountDacia : '';

        dataLayer.push({
          searchType:"dealer",
          searchTerm: this.getRouteParams,
          searchResults: this.vehicles,
          event:"search",
          googleAccount: acc,
          languageCode: document.documentElement.lang,
          countryCode: document.querySelector('html').code,
        });
    },
    async reloadFilter() {
      await this.resetParams();

      this.$router.replace({
        name: 'home',
        params: { collectionType: this.$route.params.collectionType },
        query: {}
      }).catch(err => err);

      await this.getAllFilterData();

      await this.getVehicles(false);
    },
    async resetFilters() {
      await this.resetParams();

      await this.getAllFilterData();

      await this.updateVehicles("reset");

      history.pushState({}, null, "");
    },
    async resetParams() {
      await this.$set(this.$data, 'presettedParams', {});

      await this.removeSelectedFilters();
    },
    async getFilterParams() {

      if (this.dataWasLoaded) return;

      //if it's landing
      const landingParams = this.$router.history.current.params;

      //if it's filter
      const filterParams = {}
      Object.keys(this.$route.query).forEach(key => {
        if (key !== 'dealer') filterParams[key] = this.$route.query[key]
        else this.setDealerCode(this.$route.query[key])
      });

      const hasKeys = (obj) => Object.keys(obj).length;

      if (landingParams && landingParams.landingUrl) {
        await this.getAllFilterData();

        // history.pushState({}, null, '?landing')

        return;
      } else if (hasKeys(filterParams)) {
        await this.setPresettedParams(filterParams);

        await this.getAllFilterData();

        return;
      } else {
        await this.getAllFilterData();
      }

      // await this.simpleLoad();
    },
    getParamId(params, data) {
      data = 'data' in data ? data.data : data;

      if (params) {
        params = params
          .map(i => i.toLowerCase().replace(/-/g, ' '))
          .map(paramName => {
            return data.find(item => {
              let name = item.name ? item.name : item.value;

              return name
                .replace(/-/g, ' ')
                .replace(/[åÅ]/g, 'a')
                .replace(/[öÖ]/g, 'o')
                .toLowerCase() === paramName;
            });
          })
          .map(i => {if (i) {return i.key}})
          .filter(i => i);

        return params;
      }
    },
    async setPresettedParams(params, ) {
      this.resetParams();

      for (let key in params) {

        if (!this.presettedParams[key]) this.presettedParams[key] = [];

        if (params[key] && key in params) {

          this.presettedParams[key] = 
            typeof params[key] === 'string' ? 
            [params[key]] :
            params[key]
              .filter(i => !!i)
              .map(i => i.toLowerCase())
        }
      }
    },
    async setAndUpdateFilters(data) {
      await this.setSelectedFilters(data);

      await this.getAllFilterData(data)
    },
    async setAndUpdateType(id) {
      this.setFiltersLoaded(false);

      const newResellerType = this.allTypes.find(item => {
        return item.type.id === id
      });

      const newResellerId = newResellerType.id;

      await this.setAllFinanceForms(newResellerType.financeForms.data);

      await this.resetParams();

      await this.setResellerTypeId(newResellerId);

      await this.updateBuilder();

      await this.getAllFilterData();

      this.setFiltersLoaded(true);

      await this.updateVehicles('reset');
    }
  },
  watch: {
    partnerKey: function(n) {
      if (n && this.type.length) {
        this.getFilterParams();
      }
    },
    type: function(n) {
      if (n && this.partnerKey) {
        this.getFilterParams();
      }
    },
    filters: {
      handler: async function(n) {
        if (this.dataWasLoaded) return;

        const isNotEmpty = (obj) => !!Object.keys(obj).length;

        if (isNotEmpty(n) && isNotEmpty(this.presettedParams)) {
           for (let key in this.presettedParams) {
             if (!this.presettedParams[key].length || key === 'type') continue;

              const filterParam = n.find(item => item.key === key);
              if (!filterParam) continue;

              const ids = this.getParamId(this.presettedParams[key], filterParam.items);

              this.setSelectedFilters({name: key, values: ids});
          }
        }

        if (
            isNotEmpty(n) && 
            this.$router.history.current.params && 
            this.$router.history.current.params.landingUrl
          ) {
            const landingUrl = this.$router.history.current.params.landingUrl;

            const urlParams = typeof landingUrl === 'string' ? landingUrl.split('/').filter(i => !!i) : landingUrl;

            n.some((item, index) => {
              if (index + 1 > urlParams.length) return true;

              const filterParam = n[index];

              const ids = this.getParamId([urlParams[index]], filterParam.items);

              this.setSelectedFilters({name: filterParam.key, values: ids});
            })

            await this.getAllFilterData();
        }

        this.setDataLoaded(true);

        this.getVehicles(false);
      },
      deep: true
    }
  }
};
</script>
