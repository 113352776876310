<template lang="pug">
carousel.slider(
    v-if="isHasItems"
    :loop="loop"
    :navigationEnabled="navigationEnabled"
    :navigationNextLabel="navigationNextLabel"
    :navigationPrevLabel="navigationPrevLabel"
    :perPageCustom="perPageCustom"
    :paginationPadding="paginationPadding"
    :perPage="perPage"
  )
    slide(
      v-for="(item, key) in carouselItems"
      :key="key"
    )
      CarouselItem(
        :data="item"
      )
</template>

<script>
import { mapState, } from 'vuex';

import CarouselItem from './CarouselItem';
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: "CarouselFull",
  props: {
    loop: Boolean,
    navigationEnabled: Boolean,
    perPageCustom: Array,
    paginationPadding: Number,
    perPage: Number,
  },
  data: () => ({
    navigationNextLabel: '<div class="left-chevron"></div>',
    navigationPrevLabel: '<div class="right-chevron"></div>',
  }),
  components: {
    CarouselItem,
    Carousel,
    Slide,
  },
  computed: {
    ...mapState('reseller', {
      carouselItems: state => state.customStyle.ads?.data || null,
      isHasItems: state => state.customStyle.ads && !!state.customStyle.ads.data.length
    })
  }
}
</script>

<style lang="sass">
 @import '@/style/components/Carousel/CarouselFull.sass'
</style>
