<template lang="pug">
a.slide-link( :href="data.link" )
  .slide-item
    .slide-item-image
      img(
        :src="data.image"
        :alt="data.headline"
      )
    .slide-item-data.m-t-15
      .slide-item-data-content
        div
          strong.slide-item-headline {{ data.headline }}
        .slide-item-intro {{ data.intro }}
      .slide-item-data-price
        div
          strong.slide-item-headline {{ data.delivery_time }}
        .slide-item-price
          .slide-item-price-text
            span.num {{ price }}
            span.mes.pl-1 {{ currencySymbol }}/{{ $t("month") }}
          a.btn.btn-og.invert.btn-hover.slide-item-price-btn(
            :href="data.link"
          ) {{ $t("order") }}

</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "CarouselItem",
  props: {
    data: Object,
  },
  computed: {
    ...mapState('reseller', {
      currencySymbol: state => state.resellerInfo?.market?.currency?.symbol || 'kr',
    }),
    price() {
      return this.data.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  }
}
</script>

<style lang="sass">
  @import '@/style/components/Carousel/CarouselItem.sass'
</style>
